import React from 'react';
import orderImage from './../assets/image/svg/circle-check.svg';
import { Section } from '../components/Core';
import { navigate } from 'gatsby';
import { Container, Col, Row } from 'react-bootstrap';
import BigRoundButton from '../components/BigRoundButton';
import {isMobile} from 'react-device-detect';
import Seo from '../components/Seo';

const SuccessPage = (props) => {
  const handleClick = () => {
    navigate('/');
  };

  return (
    <>
      <Seo page="success-payment" />
      <Section className="green-bg text-center">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <img src={orderImage} className="mt-4 mb-3" style={{ width: isMobile? '30%':'8%' }} />
              <h2>
                Thank you for setting up
                <br />
                your direct debit!
              </h2>
            </Col>
            <Col xs={12} md={4} className="mt-4">
              <BigRoundButton title="Return to homepage" onClick={handleClick} />
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default SuccessPage;
