import React from 'react';
import { FC } from 'react';
import './styles.scss';
interface Props {
  disabled?: boolean;
  onClick?: any;
  title?: string;
  value?: any;
  color?: 'alternative' | 'warning' | 'blue' | 'black' | undefined;
  warningColor?: boolean;
  size?: 'small' | 'large';
  ghost?: boolean;
  borderless?: boolean;
  className?: string;
}

export const BigRoundButton: FC<Props> = (props: Props) => {
  const { disabled, borderless, onClick, title, value, color, size, ghost, className } = props;

  const handleClick = () => {
    if (!disabled) {
      if (onClick && value) {
        onClick(value);
      } else if (onClick && !value) {
        onClick();
      }
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`BigRoundButton ${color === 'alternative' ? 'alternativeColor' : ''} ${className} ${
        color === 'warning' ? 'warningColor' : ''
      } ${
        color === 'blue' ? 'blueColor' : ''
      } ${size ? size : ''} text-center ${disabled ? 'disabled' : ''} ${ghost ? 'ghost' : ''} ${
        borderless ? 'borderless' : ''
      }`}
    >
      <b>{title || 'Button'}</b>
    </div>
  );
};

export default BigRoundButton;
